import Decimal from 'decimal.js';

/////////////////////////// CALCULATIONS WITH ROUNDING ///////////////////////////

export const addWithDecimals = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.add(bDec);

  return total.toFixed(2);
};

export const minusWithDecimals = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.minus(bDec);

  return total.toFixed(2);
};

export const multiplyWithDecimals = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.mul(bDec);

  return total.toFixed(2);
};

export const divideWithDecimals = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.div(bDec);

  return total.toFixed(2);
};

/////////////////////////// CALCULATIONS WITHOUT ROUNDING ///////////////////////////

export const addWithDecimalsWithoutRounding = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.add(bDec);

  return total.toNumber();
};

export const minusWithDecimalsWithoutRounding = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.minus(bDec);

  return total.toNumber();
};

export const multiplyWithDecimalsWithoutRounding = (a: string | number, b: string | number) => {
  const aDec = new Decimal(a);
  const bDec = new Decimal(b);
  const total = aDec.mul(bDec);

  return total.toNumber();
};

////////////////////////////// GENERIC CALCULATIONS //////////////////////////////

export const numberWith2Decimals = (a: string | number) => Number(new Decimal(a).toFixed(2));

export const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

export const isNegative = (a: string | number) => {
  const aDec = new Decimal(a);
  return aDec.isNegative();
};

export const isZero = (a: string | number) => {
  const aDec = new Decimal(a);
  return aDec.isZero();
};
