import { ENV } from '../config/env';
import axios, { AxiosResponse } from 'axios';
import { netCheck, onFulfilled, onRejected, onRejectedHandled } from './middleware';
import { store } from '../store/store';
import { setToast } from '../store/core/coreSlice';
import i18next from 'i18next';

export const api = axios.create({
  baseURL: ENV.API_URL,
});

export const apiRQ = axios.create({
  baseURL: ENV.API_URL,
});

api.interceptors.request.use(onFulfilled, onRejected);

apiRQ.interceptors.request.use(onFulfilled, onRejected);

// Cancel request if there is no internet
api.interceptors.request.use(netCheck, onRejected);
apiRQ.interceptors.request.use(netCheck, onRejected);

// Add a response interceptor for errors
api.interceptors.response.use((response: AxiosResponse) => {
  if (response.data.error && !response.data.success) {
    store.dispatch(setToast({ value: { show: true, msg: response.data.error, isError: true } }));
  }

  return response;
}, onRejectedHandled);

apiRQ.interceptors.response.use((response: AxiosResponse) => {
  if (response.data.success) return response;

  if (response.data.error) {
    store.dispatch(setToast({ value: { show: true, msg: response.data.error, isError: true } }));
    return response;
  }

  // TODO: move above data.error if after testing
  if (response.data.errorCode) {
    store.dispatch(
      setToast({ value: { show: true, msg: i18next.t(`API_ERRORS.${response.data.errorCode}`), isError: true } }),
    );
  }
  return response;
}, onRejectedHandled);
