import { useMutation } from 'react-query';
import { getHttpMethod } from '../../helpers';
import { GenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { queryClient } from '../../APIProvider';

const updateCreateProduct = async (variables: any): Promise<GenericResponse> => {
  const resp = await apiRQ[getHttpMethod(variables?.id)]<GenericResponse>('/products/', { data: variables });

  return resp.data;
};

export const useUpdateCreateProduct = () => {
  return useMutation<GenericResponse, Error>(updateCreateProduct, {
    onSettled: (_, __, variables: any) => {
      queryClient?.invalidateQueries(['getProducts'], { exact: false });
      queryClient?.invalidateQueries(['getProduct'], { exact: false });
    },
  });
};

const deleteProduct = async (data: any): Promise<GenericResponse> => {
  const resp = await apiRQ.delete<GenericResponse>('/products/', { data });

  return resp.data;
};

export const useDeleteProduct = () => {
  return useMutation<GenericResponse, Error>(deleteProduct, {
    onSettled: () => {
      queryClient?.invalidateQueries({ queryKey: ['getProducts'] });
    },
  });
};
