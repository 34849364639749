import { ProductInOrder } from '../../interfaces/product.interface';
import {
  addWithDecimals,
  minusWithDecimals,
  multiplyWithDecimals,
  multiplyWithDecimalsWithoutRounding,
  numberWith2Decimals,
} from '../../config/utils/calcUtils';

export interface VatCategory {
  percentage: number | string;
  cleanCost: number | string;
  discount: number | string;
  finalCleanCost: number | string;
  total: number | string;
}

export interface QuantityTotal {
  label: string;
  value: number;
}

const getProductNumbers = (product: ProductInOrder, docHasVatException?: boolean) => {
  const productCleanCostValue = multiplyWithDecimals(product.price || 0, product.quantity || 0);
  // Dot not round discount value - Rounding is going to take place for the final price
  const productDiscountValue = multiplyWithDecimalsWithoutRounding(
    (product.discountPercentage || 0) / 100,
    productCleanCostValue,
  );
  const productFinalCleanCost = minusWithDecimals(productCleanCostValue, productDiscountValue);

  // e.g. 24 || 13 || 0 || ..
  const productVatPercentageValue = docHasVatException ? 0 : Number(product.vatPercentage || 0);
  const productVatValue = multiplyWithDecimals(productFinalCleanCost, (productVatPercentageValue / 100).toFixed(2));

  return {
    productCleanCostValue,
    productDiscountValue,
    productFinalCleanCost,
    productVatPercentageValue,
    productVatValue,
  };
};

export const getVatValuesService = (products: ProductInOrder[], hasVatException?: boolean): VatCategory[] => {
  const items: Record<string, VatCategory> = {};

  for (let i = 0; i < products.length; i++) {
    const prod = products[i];
    if (!prod?.vatPercentage) {
      continue;
    }

    const {
      productCleanCostValue,
      productDiscountValue,
      productFinalCleanCost,
      productVatPercentageValue,
      productVatValue,
    } = getProductNumbers(prod, hasVatException);

    if (items[productVatPercentageValue]) {
      items[productVatPercentageValue].cleanCost = addWithDecimals(
        items[productVatPercentageValue].cleanCost,
        productCleanCostValue,
      );
      items[productVatPercentageValue].discount = addWithDecimals(
        items[productVatPercentageValue].discount,
        productDiscountValue,
      );
      items[productVatPercentageValue].finalCleanCost = addWithDecimals(
        items[productVatPercentageValue].finalCleanCost,
        productFinalCleanCost,
      );
      items[productVatPercentageValue].total = addWithDecimals(items[productVatPercentageValue].total, productVatValue);
    } else {
      items[productVatPercentageValue] = {
        percentage: productVatPercentageValue,
        cleanCost: productCleanCostValue,
        discount: productDiscountValue,
        finalCleanCost: productFinalCleanCost,
        total: productVatValue,
      };
    }
  }

  // Keep only 2 decimal digits
  for (const [key, value] of Object.entries(items)) {
    items[key].discount = numberWith2Decimals(value.discount);
    items[key].cleanCost = numberWith2Decimals(value.cleanCost);
    items[key].finalCleanCost = numberWith2Decimals(value.finalCleanCost);
    items[key].total = numberWith2Decimals(value.total);
  }

  const vatCats = Object.values(items).sort((a, b) => Number(a.percentage) - Number(b.percentage));

  return vatCats;
};

export const getTotalQuantities = (products: ProductInOrder[]): QuantityTotal[] => {
  const items: Record<string, QuantityTotal> = {};

  for (let i = 0; i < products.length; i++) {
    const prod = products[i];

    if (items[prod.unit]) {
      items[prod.unit].value = Number(addWithDecimals(items[prod.unit].value, numberWith2Decimals(prod?.quantity)));
    } else {
      items[prod.unit] = {
        label: prod.unit,
        value: numberWith2Decimals(prod.quantity),
      };
    }
  }

  return Object.values(items).sort((a, b) => b.value - a.value);
};

export const getCleanValueOfProducts = (products: ProductInOrder[]) => {
  const value = products?.reduce((acc: number, prod: ProductInOrder) => {
    return Number(addWithDecimals(acc, numberWith2Decimals(prod.finalCost)));
  }, 0);

  return value.toFixed(2);
};

export const getTotalVatFromVatCategories = (vatCategories: VatCategory[]) => {
  const res = vatCategories.reduce((acc: number, category: VatCategory) => {
    return Number(addWithDecimals(acc, numberWith2Decimals(category.total)));
  }, 0);

  return res.toFixed(2);
};

const getTotalVatOfProducts = (products: ProductInOrder[], hasVatException?: boolean) => {
  if (hasVatException) return (0).toFixed(2);

  const value = products?.reduce((acc: number, prod: ProductInOrder) => {
    const { productVatValue } = getProductNumbers(prod, hasVatException);

    return Number(addWithDecimals(acc, productVatValue));
  }, 0);

  return value.toFixed(2);
};

export const getPayableAmountOfProducts = (products: ProductInOrder[]) => {
  return addWithDecimals(getCleanValueOfProducts(products), getTotalVatOfProducts(products));
};
