import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { AnyObject } from 'yup';
import { UseMutationResult } from 'react-query';
import { GenericResponse } from '../../../interfaces/shared.interface';
import { useFormik } from 'formik';
import { getChangedFields } from '../../../config/utils/formDiffUtils';
import { isObjectEmpty } from '../../../services/utils/helpers';
import { setToast } from '../../../store/core/coreSlice';
import { formDataToSubmit, getInitialFormData } from '../../../config/utils/formUtils';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IonButton, useIonAlert } from '@ionic/react';
import { MoneyCollectionHeaderForm } from './MoneyCollectionHeaderForm';
import { MoneyCollectionFooterForm } from './MoneyCollectionFooterForm';
import { MoneyCollectionPaymentForm } from './MoneyCollectionPaymentForm';
import { useUpdateCreateMoneyCollection } from '../../../api/hooks/moneyCollection/useUpdateCreateMoneyCollection';
import { useHasActivePlan } from '../../../services/utils/useHasActivePlan';
import { useDeleteMoneyCollection } from '../../../api/hooks/moneyCollection/useDeleteMoneyCollection';
import { Loading } from '../../../components/atoms/Loading';
import { PATHS } from '../../../navigation/data';

interface Props {
  data: any;
  validationSchema: AnyObject;
  submitHook?: () => UseMutationResult<GenericResponse, Error, void, unknown>;
  deleteHook?: () => UseMutationResult<GenericResponse, Error, void, unknown>;
  isClone?: boolean;
}

export const MoneyCollectionForm = ({ data, validationSchema, isClone = false }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasActivePlan = useHasActivePlan();
  const [presentAlert] = useIonAlert();
  const initialFormData = useMemo(() => getInitialFormData(data), [data]);
  const { mutate, isLoading: isLoadingMutate, isSuccess, data: resp } = useUpdateCreateMoneyCollection();
  const { mutate: deleteEntity, isLoading: isLoadingDelete } = useDeleteMoneyCollection(history);

  useEffect(() => {
    if (isSuccess) {
      if (resp?.success) {
        if (resp?.entity?.id) {
          history.replace(PATHS.MONEY_COLLECTION);
        } else {
          history.goBack();
        }
      }
      dispatch(
        setToast({
          value: { show: true, msg: resp?.error || t('SHARED.UPDATE_SUCCESSFUL'), isError: !resp?.success || false },
        }),
      );
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: (values) => {
      console.log('==================================');
      let dataToSubmit: Record<string, any> = { ...values };
      // console.log('initialFormData: ', initialFormData);
      // console.log('dataToSubmit: ', dataToSubmit);

      if (data.id && data.id !== 'create' && !isClone) {
        const changedFields = getChangedFields(data.fields, initialFormData, dataToSubmit);

        // Nothing changed, triggered info message
        if (isObjectEmpty(changedFields)) {
          dispatch(setToast({ value: { show: true, msg: t('SHARED.NO_CHANGES'), isError: false } }));
          return;
        }

        // Remove `customerId` from changed fields
        // We don't support edit of customer
        delete changedFields.customerId;

        dataToSubmit = {
          id: data.id,
          ...changedFields,
        };
      }

      const finalData = formDataToSubmit(data.fields, dataToSubmit);

      // console.log('DocForm finalData: ', finalData);
      // return;

      // @ts-expect-error mutation
      mutate({ ...finalData, isClone });
    },
  });

  const deleteConfirmation = useCallback(() => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // @ts-expect-error mutation
            deleteEntity({ id: data.id });
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [presentAlert, t, data.id]);

  const getDeleteButton = useCallback(() => {
    if (hasActivePlan) {
      return (
        <IonButton onClick={deleteConfirmation} fill="outline" className="ion-delete-button">
          {t('FORMS.DELETE')}
        </IonButton>
      );
    }
  }, [hasActivePlan]);

  const getLoading = useCallback(() => {
    if (isLoadingDelete || isLoadingMutate) {
      return <Loading />;
    }
  }, [isLoadingDelete, isLoadingMutate]);

  return (
    <>
      {getLoading()}
      <div className="scrollable padding-b-10">
        <form onSubmit={formik.handleSubmit}>
          <div className="padding-16">
            <div className="margin-b-16">
              <MoneyCollectionHeaderForm
                formik={formik}
                traderTypeField={data.fields[0]}
                customerField={data.fields[1]}
                dateField={data.fields[2]}
                valueField={data.fields[3]}
                resetSerialNumberField={data.fields[9]}
              />
            </div>
            <div className="margin-b-16">
              <MoneyCollectionPaymentForm
                formik={formik}
                paymentTypeField={data.fields[4]}
                paycheckBank={data.fields[5]}
                paycheckNumber={data.fields[6]}
                paycheckDate={data.fields[7]}
              />
            </div>
            <div className="margin-b-16">
              <MoneyCollectionFooterForm formik={formik} notesField={data.fields[8]} />
            </div>
          </div>
          <div className="flex-space-between-start-row-wrap padding-l-16 padding-r-16">
            {getDeleteButton()}
            <IonButton type="submit" disabled={!formik.isValid || !formik.dirty} className="button-focused">
              {t('FORMS.SAVE')}
            </IonButton>
          </div>
        </form>
      </div>
    </>
  );
};
