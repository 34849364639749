import * as React from 'react';
import { useCallback } from 'react';
import { Bank } from '../../../interfaces/company.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  bankDetails: Bank[];
}

export const DocViewBankDetails = ({ bankDetails }: Props) => {
  const { t } = useTranslation();

  const getSection = useCallback(() => {
    return bankDetails.map((bank, index) => {
      return (
        <div className="simpleBorder width-196px" key={index}>
          <div className="boldText0">{`${t('PDF.BANK_NAME')} - ${t('PDF.SORT_CODE')}`}</div>
          <div className="normalText0">{`${bank?.name} - ${bank?.swiftCode}`}</div>
          <div className="normalText0 width-product-1line-200">{bank?.accountHolder}</div>
          <div className="normalText0">IBAN: {bank?.iban}</div>
        </div>
      );
    });
  }, [bankDetails]);

  return <div>{getSection()}</div>;
};
