import { GenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { getHttpMethod } from '../../helpers';
import { useMutation } from 'react-query';
import { queryClient } from '../../APIProvider';
import { store } from '../../../store/store';
import { setToast } from '../../../store/core/coreSlice';
import i18next from 'i18next';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';

interface CreateDocResponse extends GenericResponse {
  entity: InvoiceInterface;
}

const updateCreateSale = async (variables: any): Promise<CreateDocResponse> => {
  if (!variables?.id || variables?.isClone) {
    variables.currency = 'EURO';
    variables.sendToAade = false;
    variables.seriesName = variables.seriesName || '0';
  }
  delete variables.isClone;

  const resp = await apiRQ[getHttpMethod(variables?.id, variables.isClone)]<CreateDocResponse>('/invoices/', {
    data: variables,
  });

  return resp.data;
};

export const useCreateUpdateSale = () => {
  return useMutation<CreateDocResponse, Error>(updateCreateSale, {
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: ['getSale'] });
      await queryClient?.invalidateQueries({ queryKey: ['getSales'] });
    },
    onSettled: async () => {
      await queryClient?.invalidateQueries({ queryKey: ['getSale'] });
      await queryClient?.invalidateQueries({ queryKey: ['getSales'] });
    },
  });
};

/**
 * PostSaleData
 * @param data
 */
const postSale = async (data: any): Promise<GenericResponse> => {
  const resp = await apiRQ.post<GenericResponse>('/invoices/post-my-data', data);
  return resp.data;
};

export const usePostSale = () => {
  return useMutation<GenericResponse, Error>(postSale, {
    onSettled: () => {
      queryClient?.invalidateQueries({ queryKey: ['getSales'] });
    },
    onSuccess: (data) => {
      if (data.success) {
        store.dispatch(
          setToast({ value: { show: true, msg: i18next.t('SALE.DOC_SUCCESSFULLY_POSTED'), position: 'top' } }),
        );
        return;
      }

      let errorMsg = i18next.t('API_ERRORS.SOMETHING_WENT_WRONG');
      if (typeof data?.error === 'string') errorMsg = data.error;
      if (data?.errorCode) errorMsg = i18next.t(`API_ERRORS.${data.errorCode}`);

      store.dispatch(
        setToast({
          value: { show: true, msg: errorMsg, isError: true },
        }),
      );
    },
  });
};
