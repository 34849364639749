import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonButtons, IonIcon, IonInput, IonItem } from '@ionic/react';
import { BSelectSearch } from '../BSelectSearch';
import { BSelect } from '../BSelect';
import { MAP_VAT_REGIME } from '../../../services/utils/data';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';
import { getVatRegimeObject } from '../../../config/utils/dataUtils';
import i18next from 'i18next';
import { CUSTOMER_VAT_REGIME_AADE_CODE, TEST_IDS } from '../../../config/utils/constants';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../navigation/data';
import { add } from 'ionicons/icons';
import { useParams } from 'react-router';

interface Props {
  field: any;
  vatRegimeField: any;
  vatExceptionCategoryField: any;
  formik: any;
}

const setVatOfProducts = (formik: any, vatRegimeMap: any) => {
  const products = formik.values.products;
  // console.log('----------------------------------');
  // console.log('=== products: ', products);

  for (let i = 0; i < products.length; i++) {
    // console.log('=========================');
    let vatToSet = null;
    // console.log('vatRegimeMap.field: ', vatRegimeMap.field);
    if (vatRegimeMap.field) {
      vatToSet = products[i]?.vatPercentageRelation?.[vatRegimeMap.field];
    }

    if (vatRegimeMap.value !== null) {
      vatToSet = vatRegimeMap.value;
    }

    // console.log('Product: ', products[i]?.name);
    // console.log('Will set prod vat: ', vatToSet);
    if (vatToSet !== undefined) {
      formik.setFieldValue(`products[${i}].vatPercentage`, vatToSet);
    }
  }
};

const setNotesForm = (formik: any, rfPaymentCode: string) => {
  formik.setFieldValue('notes', rfPaymentCode);
};

export const CustomerForm = ({ field, vatRegimeField, vatExceptionCategoryField, formik }: Props) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const customerSelected = useMemo(() => formik?.values?.customerId, [formik]);
  const isCreate = useMemo(() => id === undefined, [id]);

  useEffect(() => {
    if (customerSelected?.rfPaymentCode) {
      setNotesForm(formik, t('SALE.RF_PAYMENT_SUFFIX', { rfPaymentCode: customerSelected?.rfPaymentCode }));
    }

    // Apply logic only on new Sales
    if (isCreate) {
      // Set vat regime when user is changing
      const vatRegimeObject = getVatRegimeObject(vatRegimeField?.data, customerSelected?.vatRegimeId);
      formik.setFieldValue('vatRegimeId', {
        label: i18next.t(`VAT_REGIME.${vatRegimeObject?.name}`),
        value: vatRegimeObject?.id,
        object: vatRegimeObject,
      });
      if (customerSelected?.vatExceptionCategoryId) {
        formik.setFieldValue('vatExceptionCategoryId', customerSelected?.vatExceptionCategoryId);
      }
    }
  }, [isCreate, customerSelected]);

  useEffect(() => {
    const vatRegimeAadeCode = formik.values?.vatRegimeId?.aadeCode || formik.values?.vatRegimeId?.object?.aadeCode;
    const vatRegimeMap = MAP_VAT_REGIME?.[vatRegimeAadeCode || '1'];

    setVatOfProducts(formik, vatRegimeMap);

    if (vatRegimeAadeCode !== CUSTOMER_VAT_REGIME_AADE_CODE.ZERO_VAT) {
      formik.setFieldValue('vatExceptionCategoryId', null);
    }
  }, [formik.values?.vatRegimeId, formik.values.products]);

  const getVatExceptionSelect = useCallback(() => {
    if (
      formik.values?.vatRegimeId?.object?.aadeCode === CUSTOMER_VAT_REGIME_AADE_CODE.ZERO_VAT ||
      formik.values?.vatRegimeId?.aadeCode === CUSTOMER_VAT_REGIME_AADE_CODE.ZERO_VAT
    ) {
      return <BSelect formik={formik} field={vatExceptionCategoryField} isRequired={true} />;
    }
  }, [formik, formik.values?.vatRegimeId]);

  const getAddEntityActionButton = useCallback(() => {
    const navigateToNewForm = () => history.push(`${PATHS.CUSTOMERS}/create`);

    return (
      <IonButtons>
        <IonButton onClick={navigateToNewForm} color="transparent">
          <IonIcon slot="icon-only" icon={add} color="success" />
        </IonButton>
      </IonButtons>
    );
  }, [history]);

  return (
    <div style={{ minHeight: 160 }} className="docForm">
      <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-row-wrap flex-gap-30">
        <BSelectSearch
          field={field}
          useHook={field.useHook}
          formik={formik}
          focusOnMount
          testId={TEST_IDS.Invoice.customerPicker}
          actionElement={getAddEntityActionButton()}
        />
        <div>
          <div className="padding-b-16 padding-l-12">{t('SALE.VAT_NUMBER')} *</div>
          <IonItem
            id={field?.name}
            className="width-300px height-50px border-radius-input g-select-border cursorPointer"
            mode="md">
            <IonInput
              className="height-48px"
              disabled
              mode="md"
              type="text"
              aria-label={field?.name}
              onKeyPress={disableSubmitOnEnter}
              name={field?.name}
              placeholder={t('SALE.VAT_PLACEHOLDER') + '...'}
              onIonChange={formik.handleChange}
              value={formik.values?.[field?.name]?.vatNumber}
            />
          </IonItem>
        </div>
        <BSelect field={vatRegimeField} formik={formik} />
        {getVatExceptionSelect()}
      </div>
      {/*<div className="margin-t-10">*/}
      {/*  - vatRegime - required TODO: epilogi katigorias ΦΠΑ `Καθεστώς ΦΠΑ` (απαλλακτικο) select with 4 options TODO:*/}
      {/*  only valid for όλα τα τιμολογια, σε αλλες περιπτωσεις πρεπει να φευγει το πεδιο OPTIONS:*/}
      {/*  /!*`- Απαλλαγή ΦΠΑ '=>' 0%`*!/*/}
      {/*  /!*`- Κανονικό ΦΠΑ '=>' default of product`*!/*/}
      {/*  /!*`- Δωδεκανήσων '=>' get reduced vat from product`*!/*/}
      {/*  Αν επιλέξει το μηδέν πρέπει να επιλέξει και άρθρο απαλλαγής*/}
      {/*</div>*/}
      {/*<div>vatRegime & vatExceptionCat should only be present if customer is business</div>*/}
    </div>
  );
};
