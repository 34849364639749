import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { Loading } from '../../components/atoms/Loading';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { useMoneyCollection } from '../../api/hooks/moneyCollection/useMoneyCollection';
import { PATHS } from '../../navigation/data';
import { CURRENCY, SUBSCRIPTION, TRADER_TYPE } from '../../interfaces/enumerables';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonButton, useIonAlert } from '@ionic/react';
import { ViewField } from '../../components/atoms/ViewField';
import { useDeleteMoneyCollection } from '../../api/hooks/moneyCollection/useDeleteMoneyCollection';
import { getLocalDate } from '../../config/utils/dateHelpers';
import { ViewTextAreaField } from '../../components/atoms/ViewTextAreaField';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedCompany } from '../../store/companies/companiesSlice';
import { pdfStyles } from '../../components/molecules/pdf/pdfStyles';
import { useCompanyLogo } from '../../api/hooks/useCompanyLogo';
import { MoneyCollectionPdf } from '../../components/molecules/pdf/moneyCollection/MoneyCollectionPdf';

export const MoneyCollectionScreen = () => {
  useHasActivePlan(true, SUBSCRIPTION.SUBTOTALS);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data: logoData } = useCompanyLogo(selectedCompany?.logoUrl || '');
  const { data, isLoading } = useMoneyCollection(id);
  const { mutate: deleteEntity, isLoading: isLoadingDelete } = useDeleteMoneyCollection(history);
  // TODO: populate currency of customer, for now it's fine because we only have euros
  const currency = useMemo(() => data?.customer?.currency || 'EURO', [data?.customer?.currency]);
  const currencySymbol = useMemo(() => CURRENCY?.[currency], [currency]);

  const editDoc = useCallback(() => {
    history.push(`${PATHS.MONEY_COLLECTION}/${id}`);
  }, [history, id]);

  const getLoading = useCallback(() => {
    if (isLoading || isLoadingDelete) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading]);

  const deleteConfirmation = useCallback(() => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // @ts-expect-error mutation
            deleteEntity({ id });
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [presentAlert, t, id]);

  const getDeleteButton = useCallback(() => {
    return (
      <IonButton onClick={deleteConfirmation} fill="outline" className="ion-delete-button">
        {t('FORMS.DELETE')}
      </IonButton>
    );
  }, [deleteConfirmation]);

  const downloadDoc = useCallback(() => {
    if (data?.id && selectedCompany) {
      const fileName = `billease-${data?.customer?.companyName}-${data.date}.pdf`;

      return (
        <PDFDownloadLink
          document={
            <MoneyCollectionPdf company={selectedCompany} doc={data} logo={logoData} currency={currencySymbol} />
          }
          fileName={fileName}
          style={pdfStyles.ionButton}>
          {t('FORMS.DOWNLOAD_PDF')}
        </PDFDownloadLink>
      );
    }
  }, [selectedCompany, data, logoData]);

  const getActionButtons = useCallback(() => {
    return (
      <div className="padding-b-10 flex-space-between-start-row-wrap width-100">
        <div className="flex-start-center-row-wrap">
          <IonButton onClick={editDoc}>{t('FORMS.EDIT')}</IonButton>
          {downloadDoc()}
        </div>
        {getDeleteButton()}
      </div>
    );
  }, [downloadDoc]);

  const getPaymentDetails = useCallback(() => {
    if (data?.paycheckBank) {
      return (
        <>
          <ViewField label={t('FORMS.PAY_CHECK_BANK')} value={data?.paycheckBank} />
          <ViewField label={t('FORMS.PAY_CHECK_NUMBER')} value={data?.paycheckNumber || ''} />
          <ViewField label={t('FORMS.PAY_CHECK_DATE')} value={getLocalDate(data?.paycheckDate || '')} />
        </>
      );
    }
  }, [data]);

  const getMainSection = useCallback(() => {
    if (data && !isLoading) {
      return (
        <div className="docForm margin-t-12">
          <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8">
            <div className="flex-start-center-row-wrap flex-gap-30 padding-b-30">
              <ViewField
                label="MONEY_COLLECTION.OTHER_END"
                value={t(`MONEY_COLLECTION.${TRADER_TYPE?.[data.traderType || 1]?.name}`)}
              />
              <ViewField label="FORMS.CUSTOMER" value={data?.customer?.companyName || ''} />
              <ViewField label="MONEY_COLLECTION.DOC_DATE" value={getLocalDate(data?.date)} />
              <ViewField label={t('MONEY_COLLECTION.PRICE', { currency: currencySymbol })} value={data?.value} />
              <ViewField
                label={t('FORMS.PAYMENT_TYPE')}
                value={t(`MONEY_COLLECTION_PAYMENT_TYPES.${data?.paymentType}`)}
              />
              {getPaymentDetails()}
            </div>
            <ViewTextAreaField label={t('FORMS.PUBLIC_NOTES_2')} value={data.notes || ''} minHeight={150} />
          </div>
        </div>
      );
    }
  }, [data]);

  const getPdf = useCallback(() => {
    if (data?.id && selectedCompany) {
      return (
        <PDFViewer style={{ width: '100%', height: '1300px' }} showToolbar={true}>
          <MoneyCollectionPdf company={selectedCompany} doc={data} logo={logoData} currency={currencySymbol} />
        </PDFViewer>
      );
    }
  }, [data, selectedCompany, logoData]);

  return (
    <div className="width-100 flex-center-center-column-nowrap">
      {getLoading()}
      <div className="page flex-start-center-column-nowrap">
        {getActionButtons()}
        {getMainSection()}
        {/*{getPdf()}*/}
      </div>
    </div>
  );
};
