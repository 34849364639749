import * as React from 'react';
import { Product } from '../../../interfaces/product.interface';
import { PATHS } from '../../../navigation/data';
import { ItemCard } from '../../../components/atoms/ItemCard';
import { ItemCardField } from '../../../components/atoms/ItemCardField';

interface Props {
  data: Product;
}

export const ProductItemCard = ({ data }: Props) => {
  return (
    <div className="width-100 itemOfList positionRelative">
      <ItemCard routerLink={`${PATHS.PRODUCTS}/${data.id}`} entityId={data.internalSerial}>
        <div className="flex-space-between-center-row-wrap width-100">
          <ItemCardField classes="margin-r-24 width-1line" width="width-100" value={data?.name} />
        </div>
      </ItemCard>
    </div>
  );
};
