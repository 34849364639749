import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSale } from '../../../api/hooks/sales/useSale';
import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectIsSelectedCompanyOwnedByUser, selectSelectedCompany } from '../../../store/companies/companiesSlice';
import { useCreateUpdateSale, usePostSale } from '../../../api/hooks/sales/useUpdateCreateSale';
import { useCompanyLogo } from '../../../api/hooks/useCompanyLogo';
import { useDeleteSale } from '../../../api/hooks/sales/useDeleteSale';
import { PATHS } from '../../../navigation/data';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { InvoicePdf } from '../../../components/molecules/pdf/InvoicePdf';
import { pdfStyles } from '../../../components/molecules/pdf/pdfStyles';
import { Loading } from '../../../components/atoms/Loading';
import { DocView } from '../../../components/molecules/docView/DocView';
import { SaleContextInterface, SaleScreenContextProvider } from './SaleScreenContext';
import { ENV } from '../../../config/env';
import { sendSharp, shareSocialOutline } from 'ionicons/icons';
import dayjs from 'dayjs';
import { setToast } from '../../../store/core/coreSlice';
import { isAfter24Hours, nowDateInUtc } from '../../../services/utils/date';
import { useHasActivePlan } from '../../../services/utils/useHasActivePlan';
import { useSendEmailToCustomer } from '../../../api/hooks/sales/useSendEmail';

export const SaleWrapper = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useSale(id);
  const hasActivePlan = useHasActivePlan();
  const [presentAlert] = useIonAlert();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const isCompanyOwnedByUser = useAppSelector(selectIsSelectedCompanyOwnedByUser);
  const { doc, setDoc } = useContext<SaleContextInterface>(SaleScreenContextProvider);
  const { mutate: updateDoc } = useCreateUpdateSale();
  const { isLoading: isLoadingMutation, mutate, isSuccess } = usePostSale();
  const { isLoading: isLoadingSendEmail, mutate: sendEmail } = useSendEmailToCustomer();
  const { data: logoData } = useCompanyLogo(data?.company?.logoUrl || selectedCompany?.logoUrl || '');
  const { mutate: deleteEntity, isLoading: isLoadingDelete } = useDeleteSale(history);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess, refetch]);

  useEffect(() => {
    if (data) {
      setDoc?.(data);
    }

    return () => {
      setDoc?.(null);
    };
  }, [data, setDoc]);

  const getLoading = useCallback(() => {
    if (isLoading || isLoadingDelete) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading, isLoadingDelete]);

  const getLoadingPostDoc = useCallback(() => {
    if (isLoadingMutation || isLoadingSendEmail) {
      return <Loading />;
    }

    return null;
  }, [isLoadingMutation, isLoadingSendEmail]);

  const editDoc = useCallback(() => {
    history.push(`${PATHS.SALES}/edit/${id}`);
  }, [history, id]);

  const deleteConfirmation = useCallback(() => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // @ts-expect-error mutation ts error
            deleteEntity({ id });
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [presentAlert, t, deleteEntity, id]);

  const postDoc = useCallback(() => {
    if (data?.id) {
      // @ts-expect-error mutation ts error
      mutate({ entityId: data?.id });
    }
  }, [data?.id, mutate]);

  const postConfirmation = useCallback(() => {
    let msg = t('FORMS.CONFIRM_POST_MSG');
    const nowDate = nowDateInUtc();
    if (doc?.documentDate !== nowDate) msg = `${t('FORMS.CONFIRM_POST_MSG_DIFF_DATE')} ${msg}`;

    presentAlert({
      mode: 'ios',
      header: msg,
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => postDoc(),
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [t, doc?.documentDate, presentAlert, postDoc]);

  const showInfoOnDownload = useCallback(() => {
    // TODO: remove check of aade code when post delivery note feature is implemented
    if (!data?.sentToAade && data?.documentType?.aadeCode !== '9.3') {
      dispatch(setToast({ value: { show: true, msg: t('FORMS.DOWNLOAD_INFO'), isError: false, duration: 10000 } }));
    }
  }, [data?.documentType?.aadeCode, data?.sentToAade, dispatch, t]);

  const downloadDoc = useCallback(() => {
    if (data?.id && selectedCompany && doc) {
      const fileName = `billease-${data?.customer?.companyName}-${data.documentDate}.pdf`;

      return (
        <PDFDownloadLink
          document={<InvoicePdf company={selectedCompany} doc={data} logo={logoData} />}
          fileName={fileName}
          onClick={showInfoOnDownload}
          style={pdfStyles.ionButton}>
          {t('FORMS.DOWNLOAD_PDF')}
        </PDFDownloadLink>
      );
    }
  }, [data, selectedCompany, doc, logoData, showInfoOnDownload, t]);

  const getDeleteButton = useCallback(() => {
    if (isCompanyOwnedByUser && hasActivePlan) {
      return (
        <IonButton onClick={deleteConfirmation} fill="outline" className="ion-delete-button">
          {t('FORMS.DELETE')}
        </IonButton>
      );
    }
  }, [deleteConfirmation, hasActivePlan, isCompanyOwnedByUser, t]);

  const updatePublicUntilDate = useCallback(() => {
    const publicUntil = dayjs()
      .add(30 * 4, 'day')
      .format('YYYY/MM/DD');

    const publicUrl = `${ENV.DOMAIN}share/doc/${id}`;
    navigator?.clipboard?.writeText(publicUrl);
    dispatch(
      setToast({ value: { show: true, msg: t('SHARED.COPIED_TO_CLIPBOARD'), isError: false, position: 'top' } }),
    );

    // @ts-expect-error mutation interface
    updateDoc({ id, publicUntil });
  }, [dispatch, id, t, updateDoc]);

  const sendEmailToCustomer = useCallback(() => {
    if (id) {
      // @ts-expect-error no worries
      sendEmail({ entityId: id });
    }
  }, [id, sendEmail]);

  const shareDocButton = useMemo(
    () => (
      <IonButton onClick={updatePublicUntilDate}>
        {t('SHARED.SHARE')}
        <IonIcon slot="end" icon={shareSocialOutline}></IonIcon>
      </IonButton>
    ),
    [t, updatePublicUntilDate],
  );

  const canSendEmail = useCallback((emailSentAt: string | null | undefined) => {
    if (!emailSentAt) return true;

    return isAfter24Hours(emailSentAt);
  }, []);

  const getSendEmailButton = useCallback(() => {
    if (hasActivePlan && data?.customer?.email && canSendEmail(data?.emailSentToCustomerAt)) {
      return (
        <IonButton onClick={sendEmailToCustomer}>
          {t('SHARED.SEND_EMAIL')}
          <IonIcon slot="end" icon={sendSharp}></IonIcon>
        </IonButton>
      );
    }
  }, [hasActivePlan, data?.customer?.email, data?.emailSentToCustomerAt, canSendEmail, sendEmailToCustomer, t]);

  const getShareDocButton = useCallback(
    (skipDocTypeCheck?: boolean) => {
      if (skipDocTypeCheck) {
        return shareDocButton;
      }

      // Show share button only for delivery note, which we cannot yet post
      if (data?.documentType?.aadeCode === '9.3') {
        return shareDocButton;
      }
    },
    [data?.documentType?.aadeCode, shareDocButton],
  );

  const getActionButtons = useCallback(() => {
    if (data?.sentToAade) {
      return (
        <div className="padding-b-10 flex-space-between-start-row-wrap width-100">
          <div className="flex-start-center-row-wrap">
            {downloadDoc()}
            {getShareDocButton(true)}
          </div>
          {getSendEmailButton()}
        </div>
      );
    }

    return (
      <div className="padding-b-10 flex-space-between-start-row-wrap width-100">
        <div className="flex-start-center-row-wrap">
          {hasActivePlan && <IonButton onClick={editDoc}>{t('FORMS.EDIT')}</IonButton>}
          {hasActivePlan && <IonButton onClick={postConfirmation}>{t('FORMS.POST_TO_MY_DATA')}</IonButton>}
          {downloadDoc()}
          {getShareDocButton()}
        </div>
        <div className="flex-start-center-row-wrap">
          <div className="padding-r-10">{getSendEmailButton()}</div>
          {getDeleteButton()}
        </div>
      </div>
    );
  }, [
    data?.sentToAade,
    hasActivePlan,
    editDoc,
    t,
    postConfirmation,
    downloadDoc,
    getShareDocButton,
    getSendEmailButton,
    getDeleteButton,
  ]);

  // const getPdf = useCallback(() => {
  //   if (data?.id && selectedCompany && doc) {
  //     return (
  //       <PDFViewer style={{ width: '100%', height: '1300px' }} showToolbar={true}>
  //         <InvoicePdf company={selectedCompany} doc={data} logo={logoData} />
  //       </PDFViewer>
  //     );
  //   }
  // }, [data, selectedCompany, logoData, doc]);

  const getDocView = useCallback(() => {
    if (data?.id && selectedCompany) {
      return <DocView company={selectedCompany} doc={data} logo={logoData} />;
    }
  }, [data, selectedCompany, logoData]);

  return (
    <>
      {getLoading()}
      {getLoadingPostDoc()}
      <div className="width-100 padding-t-16 padding-b-16 flex-start-center-column-nowrap padding-r-24 padding-l-24">
        {getActionButtons()}
        <div className="flex-center-start-column-nowrap width-100">{getDocView()}</div>
        {/*{getPdf()}*/}
      </div>
    </>
  );
};
