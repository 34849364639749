import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../../interfaces/enumerables';

interface Props {
  formik: any;
  index: number;
}

export const DocProductFinalCost = ({ formik, index }: Props) => {
  const { t } = useTranslation();
  const quantity = useMemo(() => formik.values?.products?.[index]?.quantity || 0, [formik.values?.products?.[index], index]);
  const discountPercentage = useMemo(
    () => formik.values?.products?.[index]?.discountPercentage,
    [formik.values?.products?.[index], index],
  );
  const price = useMemo(() => formik.values?.products?.[index]?.price, [formik.values?.products?.[index], index]);

  useEffect(() => {
    const initialCost = quantity * price;
    const discountValue = (discountPercentage / 100) * initialCost;
    const finalCost = Number((initialCost - discountValue).toFixed(2));

    formik.setFieldValue(`products[${index}]finalCost`, finalCost);
  }, [quantity, discountPercentage, price]);

  return (
    <div className="border-1-solid border-radius-10 padding-10">
      {t('SALE.FINAL_COST')}: {formik.values?.products?.[index]?.finalCost} {CURRENCY.EURO}
    </div>
  );
};
