import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import i18next from 'i18next';
import { EntityForm } from '../../components/molecules/EntityForm';
import { useProduct } from '../../api/hooks/products/useProduct';
import * as yup from 'yup';
import {
  Product,
  ProductCategory,
  ProductFormData,
  ProductUnit,
  SaleCategory,
  SaleCharacterization,
  VatPercentage,
} from '../../interfaces/product.interface';
import { EntityFormInterface, INPUT_TYPE } from '../../interfaces/forms.inteface';
import { useDeleteProduct, useUpdateCreateProduct } from '../../api/hooks/products/useUpdateCreateProduct';
import { useProductForm } from '../../api/hooks/products/useProductForm';
import { VatExceptionCategoryInterface } from '../../interfaces/vatExceptionCategory.interface';
import { getLocalDateTime } from '../../config/utils/dateHelpers';
import { Loading } from '../../components/atoms/Loading';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { VAT_PERCENTAGE_AADE_CODE } from '../../config/utils/constants';

// TODO: merge map functions
const mapCharacterizationsData = (data: any[], id: number | undefined | null, translationKey: string) => {
  if (id) {
    const found = data.find((entity) => entity.id == id);
    if (found) {
      return [
        {
          label: `${found?.aadeCode}: ` + i18next.t(`${translationKey}.${found.name}`), // USE name and translate
          value: found?.id,
          object: found,
        },
      ];
    }
  }

  return data.map((entity) => {
    return {
      label: `${entity?.aadeCode}: ` + i18next.t(`${translationKey}.${entity.name}`), // USE name and translate
      value: entity?.id,
      object: entity,
    };
  });
};

const mapProductCategoriesData = (data: ProductCategory[], id: number | undefined | null, translationKey: string) => {
  if (id) {
    const found = data.find((entity) => entity.id == id);
    if (found) {
      return [
        {
          label: i18next.t(`${translationKey}.${found.name}`),
          value: found?.id,
          object: found,
        },
      ];
    }
    return null;
  }

  // Return all list, and add first the default we want
  const foundIndex = data?.findIndex((entity) => entity.aadeId === '1');
  if (foundIndex > -1) {
    data.unshift(data.splice(foundIndex, 1)[0]);
  }

  return data.map((entity) => {
    return {
      label: i18next.t(`${translationKey}.${entity.name}`), // USE name and translate
      value: entity?.id,
      object: entity,
    };
  });
};

const mapProductUnitsData = (data: ProductUnit[], id: number | undefined | null, translationKey: string) => {
  if (id) {
    const found = data.find((entity) => entity.id == Number(id));
    if (found) {
      return [
        {
          label: i18next.t(`${translationKey}.${found.name}`),
          value: found?.id,
          object: found,
        },
      ];
    }
    return null;
  }

  // Return all list, and add first the default we want
  const foundIndex = data?.findIndex((entity) => entity.aadeId === '1');
  if (foundIndex > -1) {
    data.unshift(data.splice(foundIndex, 1)[0]);
  }

  return data.map((entity) => {
    return {
      label: i18next.t(`${translationKey}.${entity.name}`), // USE name and translate
      value: entity?.id,
      object: entity,
    };
  });
};

const mapVatPercentagesData = (data: VatPercentage[], translationKey: string, id?: number | undefined | null) => {
  if (id) {
    const found = data.find((entity) => entity.id == id);
    if (found) {
      return [
        {
          label: i18next.t(`${translationKey}.${found.name}`),
          value: found?.id,
          object: found,
        },
      ];
    }
    return null;
  }

  // Return all list, and add first the default we want
  const foundIndex = data?.findIndex((entity) => entity.aadeCode === '1');
  if (foundIndex > -1) {
    data.unshift(data.splice(foundIndex, 1)[0]);
  }

  return data.map((entity) => {
    return {
      label: i18next.t(`${translationKey}.${entity.name}`),
      value: entity?.id,
      object: entity,
    };
  });
};

const getFormData = (
  id?: number | string,
  data?: Product,
  extraData?: ProductFormData,
): EntityFormInterface<Product> => {
  return {
    id,
    canDelete: data?.canDelete,
    idToShow: data?.internalSerial,
    fields: [
      {
        name: 'name',
        label: 'NAME',
        placeholder: 'NAME',
        value: data?.name || '',
        type: INPUT_TYPE.B_INPUT,
        focusOnMount: true,
        inputType: 'text',
        maxLength: 180,
      },
      {
        name: 'description',
        label: 'DESCRIPTION',
        placeholder: 'DESCRIPTION',
        value: data?.description || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        maxLength: 300,
      },
      {
        name: 'internalId',
        label: 'INTERNAL_ID',
        placeholder: 'INTERNAL_ID',
        value: data?.internalId || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        tooltip: 'PRODUCT_INTERNAL_ID',
        defaultValue: null,
      },
      {
        name: 'productCategoryId',
        label: 'PRODUCT_CATEGORY',
        placeholder: 'PRODUCT_CATEGORY',
        value:
          mapProductCategoriesData(
            extraData?.productCategories || [],
            data?.productCategoryId,
            'PRODUCT_CATEGORIES',
          )?.[0] || null,
        data: extraData?.productCategories || [],
        fieldToSubmit: 'value',
        translationGroupKey: 'PRODUCT_CATEGORIES',
        type: INPUT_TYPE.ANTD_SELECT,
        heightLarge: true,
        labelStyle: 'b-select-label',
        tooltip: 'PRODUCT_CATEGORY',
      },
      {
        name: 'unitId',
        label: 'MEASUREMENT_UNIT',
        placeholder: 'MEASUREMENT_UNIT',
        value: mapProductUnitsData(extraData?.units || [], data?.unitId, 'PRODUCT_UNITS')?.[0] || null,
        translationGroupKey: 'PRODUCT_UNITS',
        fieldToSubmit: 'value',
        data: extraData?.units || [],
        type: INPUT_TYPE.ANTD_SELECT,
        heightLarge: true,
        defaultValue: null,
        labelStyle: 'b-select-label',
      },
      {
        name: 'vatPercentageId',
        label: 'VAT_PERCENTAGE',
        placeholder: 'VAT_PERCENTAGE',
        fieldToSubmit: 'value',
        translationGroupKey: 'VAT_REGIME',
        value: mapVatPercentagesData(extraData?.vatPercentages || [], 'VAT_REGIME', data?.vatPercentageId)?.[0] || null,
        data: extraData?.vatPercentages || [],
        labelStyle: 'b-select-label',
        heightLarge: true,
        type: INPUT_TYPE.ANTD_SELECT,
      },
      {
        name: 'vatExceptionCategoryId',
        label: 'VAT_EXCEPTION_CATEGORY',
        labelPrefix: (param: VatExceptionCategoryInterface) => `${param?.aadeCode}: `,
        placeholder: 'PICK_CATEGORY',
        value: data?.vatExceptionCategoryId
          ? mapCharacterizationsData(
              extraData?.vatExceptionCategories || [],
              data?.vatExceptionCategoryId,
              'VAT_EXCEPTION_CAT',
            )?.[0]
          : null,
        disabledCheck: (formikValues: Record<string, any>) => {
          return formikValues['vatPercentageId']?.object?.aadeCode !== VAT_PERCENTAGE_AADE_CODE.PERC_0;
        },
        translationGroupKey: 'VAT_EXCEPTION_CAT',
        fieldToSubmit: 'value',
        data: extraData?.vatExceptionCategories || [],
        type: INPUT_TYPE.ANTD_SELECT,
        heightLarge: true,
        allowClear: true,
        labelStyle: 'b-select-label',
        defaultValue: null,
      },
      {
        name: 'saleCategoryId',
        label: 'SALE_CATEGORY',
        labelPrefix: (param: SaleCategory) => `${param?.aadeCode}: `,
        placeholder: 'SALE_CATEGORY',
        value:
          mapCharacterizationsData(extraData?.saleCategories || [], data?.saleCategoryId, 'CHARACTERIZATIONS')?.[0] ||
          '',
        translationGroupKey: 'CHARACTERIZATIONS',
        fieldToSubmit: 'value',
        data: extraData?.saleCategories || [],
        type: INPUT_TYPE.ANTD_SELECT,
        heightLarge: true,
        labelStyle: 'b-select-label',
      },
      {
        name: 'saleCharacterizationId',
        label: 'SALE_CHARACTERIZATION',
        labelPrefix: (param: SaleCharacterization) => `${param?.aadeCode}: `,
        placeholder: 'SALE_CHARACTERIZATION',
        translationGroupKey: 'CHARACTERIZATIONS',
        value:
          mapCharacterizationsData(
            extraData?.saleCharacterizations || [],
            data?.saleCharacterizationId,
            'CHARACTERIZATIONS',
          )?.[0] || '',
        fieldToSubmit: 'value',
        data: extraData?.saleCharacterizations || [],
        type: INPUT_TYPE.ANTD_SELECT,
        heightLarge: true,
        labelStyle: 'b-select-label',
      },
      {
        name: 'validForTaxDeduction',
        label: 'VAT_DISCOUNT_ELIGIBILITY',
        placeholder: 'VAT_DISCOUNT_ELIGIBILITY',
        value: data?.validForTaxDeduction || true,
        type: INPUT_TYPE.TOGGLE,
        tooltip: 'PRODUCT_DISCOUNT_VAT_ELIGIBILITY',
      },
    ],
    onlyViewFields: [
      {
        name: 'createdAt',
        label: 'CREATED_AT',
        placeholder: 'CREATED_AT',
        value: data?.createdAt ? getLocalDateTime(String(data?.createdAt)) : '',
        type: 'text',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: data?.updatedAt ? getLocalDateTime(String(data?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: data?.updatedBy ? `${data?.updatedBy?.firstName} ${data?.updatedBy?.lastName}` : '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
    ],
    actionsOnFormValueChanges: (formik: any) => {
      const vatPerc = formik?.values?.['vatPercentageId'];
      if (vatPerc?.object?.aadeCode !== '7') {
        formik.setFieldValue('vatExceptionCategoryId', null);
      }
    },
  };
};

const validationSchema = yup.object().shape({
  name: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_NAME')),
  description: yup.string(),
  internalId: yup.string(),
  productCategoryId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  unitId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  vatPercentageId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  vatExceptionCategoryId: yup
    .object()
    .when('vatPercentageId', {
      is: (vatPercentageId: any) => vatPercentageId?.object?.aadeCode === VAT_PERCENTAGE_AADE_CODE.PERC_0,
      then: () => yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      otherwise: () => yup.object().notRequired(),
    })
    .nullable(),
  saleCategoryId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  saleCharacterizationId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  validForTaxDeduction: yup.boolean().notRequired(),
});

export const ProductScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { data: product, isLoading } = useProduct(id);
  const hasActivePlan = useHasActivePlan(id === 'create');
  const { data: formData, isLoading: isLoadingForm } = useProductForm();
  const [data, setData] = useState<{ product: Product | undefined; formData: ProductFormData }>();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if ((id === 'create' && formData) || (product && formData)) {
      setData({ product, formData });
      setIsReady(true);
    }
  }, [product, formData]);

  const getData = useCallback(() => {
    return getFormData(id, data?.product, data?.formData);
  }, [id, data?.product, data?.formData]);

  const getLoading = useCallback(() => {
    if (isLoading || isLoadingForm) {
      return <Loading backgroundFull />;
    }
  }, [isLoading, isLoadingForm]);

  return (
    <div>
      {getLoading()}
      {isReady && (
        <EntityForm
          data={getData()}
          validationSchema={validationSchema}
          submitHook={useUpdateCreateProduct}
          deleteHook={useDeleteProduct}
          deleteDataFields={['id']}
          showSubmitButton={hasActivePlan}
          showDeleteButton={hasActivePlan}
        />
      )}
      {/* TODO: πεδίο προέλευσης */}
      {/* TODO: πεδίο ποιότητα προϊόντος */}
      {/* TODO: πεδίο intrasact, select search, item contains 2 strings, name + description, probably create entity */}
      {/* TODO: add required fields in Class validator of server */}
    </div>
  );
};
