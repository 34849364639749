import { GenericResponse } from '../../../interfaces/shared.interface';
import { useMutation } from 'react-query';
import { store } from '../../../store/store';
import { setToast } from '../../../store/core/coreSlice';
import i18next from 'i18next';
import { apiRQ } from '../../api';
import { queryClient } from '../../APIProvider';

const sendEmailService = async (data: any): Promise<GenericResponse> => {
  const resp = await apiRQ.post<GenericResponse>('/invoices/send-email', data);
  return resp.data;
};

export const useSendEmailToCustomer = () => {
  return useMutation<GenericResponse, Error>(sendEmailService, {
    onSuccess: (data) => {
      if (data.success) {
        store.dispatch(
          setToast({
            value: { show: true, msg: i18next.t('SHARED.SEND_EMAIL_SUCCESS'), position: 'top', override: true },
          }),
        );
        queryClient?.invalidateQueries({ queryKey: ['getSale'] });
      }
    },
  });
};
