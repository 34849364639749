import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import {
  getCleanValueOfProducts,
  getTotalVatFromVatCategories,
  getVatValuesService,
} from '../../../../services/utils/productCost';
import { CURRENCY } from '../../../../interfaces/enumerables';
import { addWithDecimals, minusWithDecimals, multiplyWithDecimals } from '../../../../config/utils/calcUtils';
import { shouldShowTaxWithhold } from '../../../../config/utils/salesHelper';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewTotalCost = ({ doc }: Props) => {
  const products = doc?.products;
  const { t } = useTranslation();

  const getVatValues = useCallback(() => {
    return getVatValuesService(products, !!doc.vatExceptionCategoryId);
  }, [products]);

  const getCleanValue = useCallback(() => {
    return getCleanValueOfProducts(products);
  }, [products]);

  const getVatValuesView = useCallback(() => {
    const vatCats = getVatValues();

    return vatCats.map((cat, index) => {
      return (
        <div className="flex-space-between-start-row-nowrap width-100 normalText1" key={`${cat.percentage}-${index}`}>
          <div className="width64">{cat.percentage}%</div>
          <div className="width180 textAlignEnd">
            {cat.cleanCost} {CURRENCY.EURO}
          </div>
          <div className="width120 textAlignEnd">
            {cat.discount} {CURRENCY.EURO}
          </div>
          <div className="width180 textAlignEnd">
            {cat.finalCleanCost} {CURRENCY.EURO}
          </div>
          <div className="width120 textAlignEnd">
            {cat.total} {CURRENCY.EURO}
          </div>
        </div>
      );
    });
  }, [products]);

  const getTotalVat = useCallback(() => {
    if (doc.vatExceptionCategoryId) {
      return (0.0).toFixed(2);
    }

    return getTotalVatFromVatCategories(getVatValues());
  }, [getVatValues, doc.vatExceptionCategoryId]);

  const getTotalGrossAmount = useCallback(() => {
    return addWithDecimals(getCleanValue(), getTotalVat());
  }, [getCleanValue, getTotalVat]);

  const getTaxWithholdAmount = useCallback(() => {
    if (doc?.taxWithholdPercentage === '0.00' || !doc?.taxWithholdPercentage) {
      return '0.00';
    }

    return multiplyWithDecimals(getCleanValue(), Number(doc.taxWithholdPercentage) / 100);
  }, [getCleanValue, getTotalVat]);

  const getPayableAmount = useCallback(() => {
    return minusWithDecimals(getTotalGrossAmount(), getTaxWithholdAmount());
  }, [getTotalGrossAmount, getTaxWithholdAmount]);

  const getTaxWithholdSection = useCallback(() => {
    if (shouldShowTaxWithhold(doc)) {
      const val = getTaxWithholdAmount();
      return (
        <div className="flex-space-between-start-row-nowrap width-100 padding-t-6">
          <div className="flex-start-center-column-nowrap">
            <div className="flex-start-center-row-nowrap">
              <div className="boldText1 padding-r-4">{t('SALE.TAX_WITHHOLD')}</div>
              <div className="normalText0">({doc?.taxWithholdPercentage}%)</div>
            </div>
            <div className="normalText0">({t(`TAX_WITHHOLD.${doc?.taxWithhold?.name}_REASON`)})</div>
          </div>
          <div className="boldText1">
            -{val} {CURRENCY.EURO}
          </div>
        </div>
      );
    }
  }, [getTaxWithholdAmount, doc?.taxWithholdPercentage, doc?.taxWithhold?.name]);

  return (
    <div className="totalCostContainer normalText1 simpleBorder">
      <div className="flex-space-between-start-row-nowrap width100 padding-b-6 boldText1">
        <div className="width64 boldText1">{t('SALE.VAT')} %</div>
        <div className="width180 textAlignEnd boldText1">{t('SALE.CLEAN_COST_BEFORE_DISCOUNT')}</div>
        <div className="width120 textAlignEnd boldText1">{t('SALE.DISCOUNT')}</div>
        <div className="width180 textAlignEnd boldText1">{t('SALE.CLEAN_COST')}</div>
        <div className="width120 textAlignEnd boldText1">
          {t('SALE.VAT')} {CURRENCY.EURO}
        </div>
      </div>
      {getVatValuesView()}
      <div className="separator" />
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-6">
        <div className="boldText1">{t('SALE.TOTAL_CLEAN_VALUE')}</div>
        <div className="boldText1">
          {getCleanValue()} {CURRENCY.EURO}
        </div>
      </div>
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-6 boldText1">
        <div className="boldText1">{t('SALE.TOTAL_VAT')}</div>
        <div className="boldText1">
          {getTotalVat()} {CURRENCY.EURO}
        </div>
      </div>
      <div className="flex-space-between-start-row-nowrap width-100 boldText1">
        <div className="boldText1">{t('SALE.TOTAL')}</div>
        <div className="boldText1">
          {getTotalGrossAmount()} {CURRENCY.EURO}
        </div>
      </div>
      {getTaxWithholdSection()}
      <div className="flex-space-between-start-row-nowrap width-100 boldText1 padding-t-20">
        <div className="boldText1">{t('SALE.PAYABLE_AMOUNT')}</div>
        <div className="boldText1">
          {getPayableAmount()} {CURRENCY.EURO}
        </div>
      </div>
    </div>
  );
};
