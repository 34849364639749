import * as React from 'react';
import { useCallback } from 'react';
import { CURRENCY } from '../../../../interfaces/enumerables';
import { useTranslation } from 'react-i18next';
import { InvoiceInterface } from '../../../../interfaces/invoice.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import {
  getCleanValueOfProducts,
  getTotalVatFromVatCategories,
  getVatValuesService,
} from '../../../../services/utils/productCost';
import { addWithDecimals, minusWithDecimals, multiplyWithDecimals } from '../../../../config/utils/calcUtils';
import { shouldShowTaxWithhold } from '../../../../config/utils/salesHelper';

interface Props {
  doc: InvoiceInterface;
}

export const PdfTotalCost = ({ doc }: Props) => {
  const products = doc?.products;
  const { t } = useTranslation();

  const getVatValues = useCallback(() => {
    // CHECK IF SOMEHOW exceptionCategory is not set if vatRegime is zero
    return getVatValuesService(products, !!doc?.vatExceptionCategoryId);
  }, [products]);

  const getCleanValue = useCallback(() => {
    return getCleanValueOfProducts(products);
  }, [products]);

  const getVatValuesView = useCallback(() => {
    const vatCats = getVatValues();

    return vatCats.map((cat, index) => {
      return (
        <View
          style={[styles.container1, styles.width100, pdfStyles.smallTitleRegular]}
          key={`${cat.percentage}-${index}`}>
          <Text style={[styles.width64]}>{cat.percentage}%</Text>
          <Text style={[styles.width180, styles.textAlignEnd]}>
            {cat.cleanCost} {CURRENCY.EURO}
          </Text>
          <Text style={[styles.width120, styles.textAlignEnd]}>
            {cat.discount} {CURRENCY.EURO}
          </Text>
          <Text style={[styles.width180, styles.textAlignEnd]}>
            {cat.finalCleanCost} {CURRENCY.EURO}
          </Text>
          <Text style={[styles.width120, styles.textAlignEnd]}>
            {cat.total} {CURRENCY.EURO}
          </Text>
        </View>
      );
    });
  }, [products]);

  const getTotalVat = useCallback(() => {
    if (doc.vatExceptionCategoryId) {
      return (0.0).toFixed(2);
    }

    return getTotalVatFromVatCategories(getVatValues());
  }, [getVatValues, doc.vatExceptionCategoryId]);

  const getTotalGrossAmount = useCallback(() => {
    return addWithDecimals(getCleanValue(), getTotalVat());
  }, [getCleanValue, getTotalVat]);

  const getTaxWithholdAmount = useCallback(() => {
    if (doc?.taxWithholdPercentage === '0.00' || !doc?.taxWithholdPercentage) {
      return '0.00';
    }

    return multiplyWithDecimals(getCleanValue(), Number(doc.taxWithholdPercentage) / 100);
  }, [getCleanValue, getTotalVat]);

  const getPayableAmount = useCallback(() => {
    return minusWithDecimals(getTotalGrossAmount(), getTaxWithholdAmount());
  }, [getTotalGrossAmount, getTaxWithholdAmount]);

  const getTaxWithholdSection = useCallback(() => {
    if (shouldShowTaxWithhold(doc)) {
      const val = getTaxWithholdAmount();
      return (
        <View style={[styles.container1, styles.width100, styles.paddingT6]}>
          <View>
            <View style={pdfStyles.flexStartCenterRow}>
              <Text style={[pdfStyles.smallTitle, styles.paddingR4]}>{t('SALE.TAX_WITHHOLD')}</Text>
              <Text style={pdfStyles.smallTitleRegular}>({doc?.taxWithholdPercentage}%)</Text>
            </View>
            <Text style={pdfStyles.smallTitleRegular}>({t(`TAX_WITHHOLD.${doc?.taxWithhold?.name}_REASON`)})</Text>
          </View>
          <Text style={pdfStyles.smallTitle}>
            -{val} {CURRENCY.EURO}
          </Text>
        </View>
      );
    }
  }, [getTaxWithholdAmount, doc?.taxWithholdPercentage, doc?.taxWithhold?.name]);

  return (
    <View style={[styles.container, pdfStyles.simpleBorder]}>
      <View style={[styles.container1, styles.width100, styles.paddingB6, pdfStyles.smallTitle]}>
        <View style={[styles.width64]}>
          <Text>{t('SALE.VAT')} %</Text>
        </View>
        <View style={[styles.width180, styles.textAlignEnd]}>
          <Text>{t('SALE.CLEAN_COST_BEFORE_DISCOUNT')}</Text>
        </View>
        <View style={[styles.width120, styles.textAlignEnd]}>
          <Text>{t('SALE.DISCOUNT')}</Text>
        </View>
        <View style={[styles.width180, styles.textAlignEnd]}>
          <Text>{t('SALE.CLEAN_COST')}</Text>
        </View>
        <View style={[styles.width120, styles.textAlignEnd]}>
          <Text>
            {t('SALE.VAT')} {CURRENCY.EURO}
          </Text>
        </View>
      </View>
      {getVatValuesView()}
      <View style={styles.separator} />
      <View style={[styles.container1, styles.width100, styles.paddingB6, pdfStyles.smallTitle]}>
        <Text>{t('SALE.TOTAL_CLEAN_VALUE')}</Text>
        <Text>
          {getCleanValue()} {CURRENCY.EURO}
        </Text>
      </View>
      <View style={[styles.container1, styles.width100, styles.paddingB6, pdfStyles.smallTitle]}>
        <Text>{t('SALE.TOTAL_VAT')}</Text>
        <Text>
          {getTotalVat()} {CURRENCY.EURO}
        </Text>
      </View>
      <View style={[styles.container1, styles.width100, pdfStyles.smallTitle]}>
        <Text>{t('SALE.TOTAL')}</Text>
        <Text>
          {getTotalGrossAmount()} {CURRENCY.EURO}
        </Text>
      </View>
      {getTaxWithholdSection()}
      <View style={[styles.container1, styles.width100, pdfStyles.smallTitle, styles.paddingT16]}>
        <Text>{t('SALE.PAYABLE_AMOUNT')}</Text>
        <Text>
          {getPayableAmount()} {CURRENCY.EURO}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 300,
    fontSize: 8,
  },
  container1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  width100: {
    width: '100%',
  },
  width180: {
    width: 180,
  },
  width120: {
    width: 120,
  },
  width64: {
    width: 64,
  },
  paddingB6: {
    paddingBottom: 6,
  },
  paddingR4: {
    paddingRight: 4,
  },
  paddingT6: {
    paddingTop: 6,
  },
  paddingT16: {
    paddingTop: 16,
  },
  separator: {
    height: 0.1,
    backgroundColor: '#1e2023',
    width: '100%',
    marginTop: 4,
    marginBottom: 4,
  },
  textAlignEnd: {
    textAlign: 'right',
  },
});
