import { ItemCard } from '../../../components/atoms/ItemCard';
import { PATHS } from '../../../navigation/data';
import { ItemCardField } from '../../../components/atoms/ItemCardField';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { getLocalDate } from '../../../config/utils/dateHelpers';
import { CURRENCY } from '../../../interfaces/enumerables';
import { TypePill } from '../../../components/atoms/TypePill';
import { useTranslation } from 'react-i18next';
import { MoneyCollection } from '../../../interfaces/moneyCollection.interface';
import { CloneEntityButton } from '../../../components/molecules/CloneEntityButton';
import { MONEY_COLLECTION_TRADER_LABEL } from '../../../api/hooks/useFormMoneyCollection';

interface Props {
  data: MoneyCollection;
}

export const MoneyCollectionItemCard = ({ data }: Props) => {
  const { t } = useTranslation();
  const currencySymbol = useMemo(() => CURRENCY[data?.currency || 'EURO'], []);

  const getDate = useCallback(() => {
    return getLocalDate(data?.date);
  }, [data]);

  return (
    <div className="width-100 itemOfList positionRelative">
      <ItemCard routerLink={`${PATHS.MONEY_COLLECTION}/view/${data.id}`} entityId={data.serialNumber}>
        <>
          <TypePill type={t(`MONEY_COLLECTION_PAYMENT_TYPES.${data?.paymentType}`)} />
          <TypePill type={t(`MONEY_COLLECTION.${MONEY_COLLECTION_TRADER_LABEL[data?.traderType || 1]}`)} top={24} />
          <div className="flex-space-between-center-row-wrap width-100">
            <ItemCardField classes="margin-r-24" width="width-180px" value={data?.customer?.companyName || ''} />
            <ItemCardField classes="margin-r-24" width="width-180px" value={`${data?.value} ${currencySymbol}`} />
            <ItemCardField classes="margin-r-24" width="width-180px" value={getDate()} />
          </div>
        </>
      </ItemCard>
      <CloneEntityButton data={{ id: data.id }} path={PATHS.CLONE_MONEY_COLLECTION} />
    </div>
  );
};
