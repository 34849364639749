import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from './data';
import { PublicDocScreen } from '../screens/Public/PublicDocScreen';
import { LoginScreen } from '../screens/Login/LoginScreen';

export const PublicNavigator = () => {
  return (
    <Switch>
      <Route path={PATHS.HOME} exact={true} component={LoginScreen} />
      <Route path={`${PATHS.SHARED_DOC}/:id`} exact={true} component={PublicDocScreen} />
      <Route path="*">
        <Redirect to={PATHS.HOME} />
      </Route>
    </Switch>
  );
};
