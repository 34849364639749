import { LoginDTO, LoginResponse } from '../interfaces/auth.interface';
import { api } from '../api/api';
import { GenericResponse } from '../interfaces/shared.interface';

export const loginService = async (userData: LoginDTO): Promise<LoginResponse> => {
  const resp = await api.post<LoginResponse>('/auth/jwt-auth', userData);

  // Check if an error occurred and caught from interceptor
  if (resp?.data?.thrownError) {
    return { success: false };
  }

  const { user } = resp.data;

  return { success: true, user };
};

export const logoutService = async (): Promise<GenericResponse> => {
    // const resp = await api.post<IGenericResponse>('auth/logout', { ...notificationToken });
    //
    // // Check if an error occurred and caught from interceptor
    // if (resp?.data?.thrownError) {
    //     return { success: false };
    // }

    return { success: true };
};
