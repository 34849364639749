import { CustomerVatRegime, DocPurpose, InvoiceInterface } from '../../interfaces/invoice.interface';
import { EntityFormInterface, INPUT_TYPE } from '../../interfaces/forms.inteface';
import i18next from 'i18next';
import * as yup from 'yup';
import { useLocation, useParams } from 'react-router';
import { DocForm } from '../../components/molecules/docForm/DocForm';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomers } from '../../api/hooks/customers/useCustomers';
import { DocumentTypeInterface } from '../../interfaces/documentType.interface';
import { getDueDate, nowDate } from '../../config/utils/dateHelpers';
import { emptyProductObject } from '../../services/utils/data';
import { Loading } from '../../components/atoms/Loading';
import { useForm } from '../../api/hooks/useForm';
import { PaymentType } from '../../interfaces/paymentTypes.interface';
import { VatExceptionCategoryInterface } from '../../interfaces/vatExceptionCategory.interface';
import { useSale } from '../../api/hooks/sales/useSale';
import { images } from '../../theme/assets/img';
import { DataPlaceholder } from '../../components/atoms/DataPlaceholder';
import { PATHS } from '../../navigation/data';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { defaultTaxWithhold } from '../../config/utils/salesHelper';
import { patternTwoDigitsAfterComma } from '../../config/utils/calcUtils';
import dayjs from 'dayjs';
import { CUSTOMER_VAT_REGIME_AADE_CODE, DOCUMENT_TYPE_AADE_CODE } from '../../config/utils/constants';

const defaultValueReceipt = (docTypes: DocumentTypeInterface[], selectedDocTypeId?: number | null) => {
  if (selectedDocTypeId) {
    const found = docTypes.find((ent) => ent.id === selectedDocTypeId);

    return {
      label: `(${found?.aadeCode}) ` + i18next.t(`DOC_TYPES.${found?.name}`),
      value: found?.id,
      object: found,
    };
  }

  // Not needed currently, was used to select `Τιμολόγιο Πώλησης` as default, but it is default with the current sorting from server
  // const docType = docTypes?.find((doc) => doc.aadeCode === '1.1');

  const docType = docTypes[0];
  if (docType) {
    return {
      label: `(${docType?.aadeCode}) ` + i18next.t(`DOC_TYPES.${docType?.name}`),
      value: docType?.id,
      object: docType,
    };
  }

  // Default value in case no list is returned
  return {
    label: i18next.t('SALE.SELECT_DOC_TYPE'),
    value: 7,
    object: {},
  };
};

const defaultPaymentType = (data: PaymentType[]) => {
  const entity = data?.find((doc) => doc.aadeCode === '5');
  return {
    label: i18next.t(`PAYMENT_TYPES.${entity?.name}`),
    value: entity?.id,
    object: entity,
  };
};

const defaultVatExceptionCat = (data: VatExceptionCategoryInterface[]) => {
  return data?.map((entity) => {
    return {
      label: i18next.t(`VAT_EXCEPTION_CAT.${entity?.name}`),
      value: entity?.id,
      object: entity,
    };
  });
};

const defaultVatRegime = (data: CustomerVatRegime[], initVal?: CustomerVatRegime) => {
  if (initVal) {
    return [
      {
        label: i18next.t(`VAT_REGIME.${initVal?.name}`),
        value: initVal?.id,
        object: initVal,
      },
    ];
  }

  return data?.map((entity) => {
    return {
      label: i18next.t(`VAT_REGIME.${entity?.name}`),
      value: entity?.id,
      object: entity,
    };
  });
};

const defaultValueDocPurpose = (entities: DocPurpose[]) => {
  return entities?.map((entity) => {
    return {
      label: `(${entity?.aadeCode}) ` + i18next.t(`DOC_PURPOSES.${entity?.name}`),
      value: entity?.id,
      object: entity,
    };
  });
};

const prefillForm = (entity: InvoiceInterface | null | undefined, isClone?: boolean) => {
  if (!entity) return null;

  return {
    customer: entity?.customer,
    vatRegime: entity?.vatRegime,
    vatExceptionCategory: entity?.vatExceptionCategoryId,
    documentType: entity?.documentTypeId,
    seriesName: entity?.seriesName,
    seriesNumber: entity?.serialNumber,
    documentPurpose: entity?.docPurposeId,
    documentDate: isClone ? nowDate() : new Date(entity?.documentDate).toISOString(),
    dueDate: getDueDate(entity?.dueDate),
    shipDate: isClone ? nowDate() : new Date(entity?.shipDate || nowDate()).toISOString(),
    paymentType: entity?.paymentType,
    shipping: entity?.shipping,
    shipFrom: entity?.shipFrom,
    shipTo: entity?.shipTo,
    shipToCity: entity?.shipToCity,
    shipToPostalCode: entity?.shipToPostalCode,
    notes: entity?.notes,
    products: entity?.products,
    taxWithhold: entity?.taxWithhold || null,
  };
};

const getDefaultDeliverToFromValue = (isEditOrClone: boolean, translationKey: string) => {
  if (isEditOrClone) return '';

  return i18next.t(`SALE.${translationKey}`);
};

const getFormData = (
  id?: number | string,
  data?: any,
  entityData?: InvoiceInterface | null | undefined,
  isClone?: boolean,
): EntityFormInterface<InvoiceInterface> => {
  const prefillData = prefillForm(entityData, isClone);
  const isEditOrClone = isClone || !!id;

  return {
    id,
    fields: [
      {
        name: 'customerId',
        label: 'PICK_CUSTOMER',
        placeholder: 'CUSTOMER',
        value: prefillData?.customer || null,
        fieldValue: 'companyName',
        fieldValue2: 'lastName',
        fieldToSubmit: 'id',
        fieldToSearch: 'vatNumber',
        // @ts-expect-error happens
        useHook: useCustomers,
        required: true,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
      },
      {
        name: 'vatRegimeId',
        label: 'VAT_REGIME',
        placeholder: 'VAT_REGIME',
        value: defaultVatRegime(data.customerVatRegimes || [], prefillData?.vatRegime)[0],
        fieldToSubmit: 'id',
        fieldToSubmit2: 'value',
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'VAT_REGIME',
        data: data?.customerVatRegimes,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
      },
      {
        name: 'vatExceptionCategoryId',
        label: 'VAT_EXCEPTION_CAT',
        placeholder: 'VAT_EXCEPTION_CAT',
        value: prefillData?.vatExceptionCategory,
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'VAT_EXCEPTION_CAT',
        fieldToSubmit: 'value',
        data: data?.vatExceptionCategories,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
      },
      {
        name: 'documentTypeId',
        label: 'DOCUMENT_TYPE',
        placeholder: 'DOCUMENT_TYPE',
        value: defaultValueReceipt(data?.docTypes || [], entityData?.documentTypeId),
        fieldValue: 'name',
        fieldToSubmit: 'value',
        type: INPUT_TYPE.ANTD_SELECT,
        data: data?.docTypes || [],
      },
      {
        name: 'docPurposeId',
        label: 'DOC_PURPOSE',
        placeholder: 'DOC_PURPOSE',
        value: prefillData?.documentPurpose || defaultValueDocPurpose(data?.docPurposes || [])?.[0],
        fieldValue: 'name',
        fieldToSubmit: 'value',
        type: INPUT_TYPE.ANTD_SELECT,
        required: true,
        labelStyle: 'padding-b-16 padding-l-12',
        labelPrefix: (param: DocPurpose) => `(${param?.aadeCode}) `,
        translationGroupKey: 'DOC_PURPOSES',
        data: data?.docPurposes || [],
      },
      {
        name: 'documentDate',
        label: 'DOC_DATE',
        placeholder: 'DOC_DATE',
        value: prefillData?.documentDate || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'dueDate',
        label: 'DOC_DATE',
        placeholder: 'DOC_DATE',
        value: prefillData?.dueDate || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'shipDate',
        label: 'DELIVERY',
        placeholder: 'DELIVERY',
        value: prefillData?.shipDate || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'seriesName',
        label: 'SERIES_NAME',
        placeholder: 'SERIES_NAME_PLACEHOLDER',
        value: prefillData?.seriesName || '',
        type: INPUT_TYPE.TEXT,
      },
      {
        name: 'serialNumber',
        label: 'SERIAL_NUMBER',
        placeholder: 'SERIAL_NUMBER',
        value: prefillData?.seriesNumber || '',
        type: INPUT_TYPE.NUMBER,
      },
      {
        name: 'products',
        label: 'PRODUCTS',
        placeholder: 'PRODUCTS',
        value: prefillData?.products || [emptyProductObject],
      },
      {
        name: 'paymentTypeId',
        label: 'PAYMENT_TYPE',
        placeholder: 'PAYMENT_TYPE',
        value: prefillData?.paymentType || defaultPaymentType(data?.paymentTypes),
        fieldValue: 'name',
        fieldToSubmit: 'id',
        fieldToSubmit2: 'value',
        type: INPUT_TYPE.ANTD_SELECT,
        data: data?.paymentTypes || [],
      },
      {
        name: 'shipping',
        label: 'SHIPPING',
        placeholder: 'SHIPPING_PLACEHOLDER',
        value: prefillData?.shipping || '',
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        fieldToSubmit: 'value',
        maxLength: 40,
      },
      {
        name: 'shipFrom',
        label: 'DELIVERY_PLACE',
        placeholder: 'DELIVERY_PLACE',
        value: prefillData?.shipFrom || getDefaultDeliverToFromValue(isEditOrClone, 'OUR_PLACE'),
        type: INPUT_TYPE.TEXT,
        maxLength: 198,
      },
      {
        name: 'shipTo',
        label: 'DELIVERY_RECEIVE_ADDRESS',
        placeholder: 'DELIVERY_RECEIVE_ADDRESS',
        value: prefillData?.shipTo || getDefaultDeliverToFromValue(isEditOrClone, 'THEIR_PLACE'),
        type: INPUT_TYPE.TEXT,
        maxLength: 198,
      },
      {
        name: 'notes',
        label: 'PUBLIC_NOTES',
        placeholder: 'PUBLIC_NOTES_DESCRIPTION',
        value: prefillData?.notes || '',
        type: INPUT_TYPE.TEXT_AREA,
      },
      {
        name: 'totalValue',
        label: 'TOTAL_CLEAN_VALUE',
        placeholder: 'TOTAL_CLEAN_VALUE',
        value: entityData?.totalValue || 0,
        type: INPUT_TYPE.B_INPUT,
      },
      {
        name: 'taxWithholdId',
        label: 'TAX_WITHHOLD_LABEL',
        placeholder: 'TAX_WITHHOLD_PLACEHOLDER',
        value: prefillData?.taxWithhold || defaultTaxWithhold(data.taxWithholds || [])[0],
        fieldToSubmit: 'id',
        fieldToSubmit2: 'value',
        labelStyle: 'padding-b-16 padding-l-4',
        translationGroupKey: 'TAX_WITHHOLD',
        data: data?.taxWithholds,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
      },
      {
        name: 'shipToCity',
        label: 'DELIVERY_RECEIVE_CITY',
        placeholder: 'DELIVERY_RECEIVE_CITY',
        value: prefillData?.shipToCity || '',
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        fieldToSubmit: 'value',
        maxLength: 40,
      },
      {
        name: 'shipToPostalCode',
        label: 'DELIVERY_RECEIVE_POSTAL_CODE',
        placeholder: 'DELIVERY_RECEIVE_POSTAL_CODE',
        value: prefillData?.shipToPostalCode || '',
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        fieldToSubmit: 'value',
        maxLength: 40,
      },
    ],
    onlyViewFields: [
      {
        name: 'mark',
        label: 'MARK',
        placeholder: 'MARK',
        value: 'NO',
        type: 'text',
        disabled: true,
      },
      {
        name: 'aadeUID',
        label: 'AADE_UID',
        placeholder: 'AADE_UID',
        value: 'NO',
        type: 'text',
        disabled: true,
      },
      {
        name: 'sentToAadeAt',
        label: 'SENT_TO_AADE',
        placeholder: 'SENT_TO_AADE',
        value: i18next.t('FORMS.SENT_TO_AADE_FALSE'),
        type: 'text',
        disabled: true,
      },
      {
        name: 'createdAt',
        label: 'CREATED_AT',
        placeholder: 'CREATED_AT',
        value: '',
        type: 'text',
        disabled: true,
      },
      {
        name: 'updatedBy',
        label: 'UPDATED_BY',
        placeholder: 'UPDATED_BY',
        value: '',
        type: 'text',
        fieldValue: 'typeOf',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

// TODO: add validation
const validationSchema = yup.object({
  // name: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_NAME')),
  customerId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  vatRegimeId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  vatExceptionCategoryId: yup
    .mixed()
    .when('vatRegimeId', {
      is: (vatRegimeId: any) => vatRegimeId?.object?.aadeCode === CUSTOMER_VAT_REGIME_AADE_CODE.ZERO_VAT,
      then: () => yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      otherwise: () => yup.mixed().notRequired(),
    })
    .nullable(),
  documentTypeId: yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  docPurposeId: yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  products: yup.array().of(
    yup.object().shape({
      name: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      quantity: yup
        .number()
        .required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD'))
        .min(0.01)
        .test((value) => patternTwoDigitsAfterComma.test(String(value))),
      price: yup
        .number()
        .nullable()
        .test('documentTypeId', i18next.t('FORMS.ERRORS.REQUIRED_FIELD'), function (value) {
          if (this.from?.[1]?.value?.documentTypeId?.object?.aadeCode !== DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE) {
            return !!value && value > 0 && patternTwoDigitsAfterComma.test(String(value));
          }

          return patternTwoDigitsAfterComma.test(String(value));
        }),
      discountPercentage: yup
        .number()
        .required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD'))
        .min(0)
        .max(100)
        .test((value) => patternTwoDigitsAfterComma.test(String(value))),
      finalCost: yup.number(),
      vatPercentage: yup.number().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
    }),
  ),
  paymentTypeId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  taxWithholdId: yup.object().notRequired(),
  notes: yup.string().notRequired(),
  totalValue: yup.number(),
  shipFrom: yup.string().notRequired().max(198),
  shipTo: yup.string().notRequired().max(198),
  shipping: yup
    .mixed()
    .when('documentTypeId', {
      is: (documentTypeId: any) => {
        return documentTypeId?.object?.aadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE;
      },
      then: () => yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      otherwise: () => yup.mixed().notRequired(),
    })
    .nullable(),
  shipToCity: yup
    .mixed()
    .when('documentTypeId', {
      is: (documentTypeId: any) => {
        return documentTypeId?.object?.aadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE;
      },
      then: () => yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      otherwise: () => yup.mixed().notRequired(),
    })
    .nullable(),
  shipToPostalCode: yup
    .mixed()
    .when('documentTypeId', {
      is: (documentTypeId: any) => {
        return documentTypeId?.object?.aadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE;
      },
      then: () => yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      otherwise: () => yup.mixed().notRequired(),
    })
    .nullable(),
  documentDate: yup.date().required(),
  dueDate: yup
    .date()
    .test('Due date - Document date', 'Doc date must be before or same as due date', (value, context) => {
      const documentDate = dayjs(context?.parent?.documentDate || '');
      const dayjsDueDate = dayjs(value || '');

      return !dayjsDueDate.isBefore(documentDate, 'day');
    }),
  shipDate: yup
    .date()
    .notRequired()
    .test('Ship date - Document date', 'Doc date must be before or same as ship date', (value, context) => {
      if (context?.parent?.documentTypeId?.object?.aadeCode !== DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE) {
        return true;
      }

      const documentDate = dayjs(context?.parent?.documentDate || '');
      const dayjsDueDate = dayjs(value || '');

      return !dayjsDueDate.isBefore(documentDate, 'day');
    }),
});

interface FormState {
  formData: any;
  entityData: InvoiceInterface | null;
}

export const NewSaleScreen = () => {
  useHasActivePlan(true);
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const isClone = useMemo(() => pathname.includes(PATHS.CLONE_SALE), [pathname]);
  const isCreate = useMemo(() => id === undefined, [id]);
  const { data: entityData, isLoading: isLoadingEntity } = useSale(id);
  const { data: formData, isLoading } = useForm();
  const [data, setData] = useState<FormState | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isPageDisabled, setIsPageDisabled] = useState(false);
  const entityDataReady = useMemo(() => isCreate || entityData, [isCreate, entityData]);

  useEffect(() => {
    if (formData && entityDataReady) {
      const helpData: FormState = { formData, entityData: null };
      if (id && entityData) {
        helpData.entityData = entityData;
      }

      setData(helpData);

      if (id && entityData?.sentToAade && !isClone) {
        setIsPageDisabled(true);
      } else {
        setIsPageDisabled(false);
      }

      setIsReady(true);
    }
  }, [formData, entityData]);

  const getDisabledSection = useCallback(() => {
    if (isPageDisabled) {
      return (
        <DataPlaceholder
          imgSource={images.somethingWentWrong}
          msg={'SHARED.UNABLE_TO_EDIT_ALREADY_POSTED_TO_MY_DATA'}
        />
      );
    }
  }, [isPageDisabled]);

  const getMainSection = useCallback(() => {
    if (isReady && !isPageDisabled) {
      const dataForm = getFormData(id, data?.formData, data?.entityData, isClone);
      return <DocForm data={dataForm} validationSchema={validationSchema} isClone={isClone} />;
    }
  }, [isReady, isPageDisabled, data, isClone]);

  return (
    <div>
      {(isLoading || isLoadingEntity) && <Loading backgroundFull />}
      {getMainSection()}
      {getDisabledSection()}
    </div>
  );
};
