import * as React from 'react';
import { useCallback } from 'react';
import { useCompanies } from '../../../api/hooks/useCompanies';
import { CompanyCard } from './components/CompanyCard';
import { Loading } from '../../../components/atoms/Loading';
import { CompanyDetails } from './components/CompanyDetails';

export const CompaniesScreen = () => {
  const { data, isLoading } = useCompanies();

  const getCompanies = useCallback(() => {
    return data?.map((comp) => <CompanyCard company={comp} key={comp.id} />);
  }, [data]);

  return (
    <>
      <div className="width-100 flex-center-center-column-nowrap">
        <div className="page flex-center-center-column-nowrap">
          <div className="flex-center-center-row-wrap flex-gap-12">{getCompanies()}</div>
        </div>
        <div className="margin-t-16 margin-b-80">
          <CompanyDetails />
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};
