import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';

const getSale = async (id: string): Promise<InvoiceInterface> => {
  const resp = await apiRQ.get<InvoiceInterface>(`/invoices/${id}`);
  return resp.data;
};

export const useSale = (id: string) => {
  return useQuery<InvoiceInterface, Error>(['getSale', id], () => getSale(id), {
    enabled: id !== 'create' && !!id,
    cacheTime: 200,
    staleTime: 200,
    select: (data) => {
      if (data?.staticCompany) data.company = data?.staticCompany;
      if (data?.staticCustomer) data.customer = data?.staticCustomer;

      return data;
    }
  });
};
